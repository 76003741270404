import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner } from "../../components/Banners/HalvaSKehshbehkom"
import { CardWithMaxBenefits } from "../../components/CardWithMaxBenefits"
import { FormPKW } from "../../components/FormPKW"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { CashbackHalva } from "../../components/CashbackHalva"
import { Partners } from "../../components/PartnersNew"
import { NewFooter } from "../../components/NewFooter"

import { getPageData } from "../../helpers/getPageData"

import { PageData } from "../../interfaces/pageProps"

const bannerTitle = <>Кэшбэк до 10%</>

const bannerSubTitle = (
  <>
    — До 17% на остаток по карте
    <br />— Обслуживание 0 ₽
  </>
)

const TITLE =
  "Карта Халва с кэшбэком до 10% | Рассрочка до 24 месяцев | 17% на остаток | Выпуск Бесплатно"

const DESCR =
  "Оформить карту Халва с кешбэком онлайн. Получите рассрочку на 24 месяца на миллионы товаров. Кэшбэк до 10% и 15% на остаток."

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  return (
    <Layout noHeader noFooter title={TITLE} description={DESCR} noIndex={noIndex}>
      <NewHeader redLogoWithSovcombank />
      <Banner title={bannerTitle} description={bannerSubTitle} orderNum="1" />
      <CardWithMaxBenefits orderNum="2" />
      <FormPKW
        emptyForm={false}
        dataLayerName="shortPersonalForm"
        orderNum="3"
        progressBar
        isGreenApprove
      />
      <NewCalculator newHint orderNum="4" />
      <CashbackHalva orderNum="5" />
      <Partners orderNum="6" />
      <NewFooter ligal={ligal} orderNum="7" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/pkw/halva-s-kehshbehkom/") {
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
